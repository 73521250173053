
.carousel-control-next-icon,.carousel-control-prev-icon {
    filter: invert(1);
}
.react-multiple-carousel__arrow--right {
  background: transparent !important;
}
.react-multiple-carousel__arrow::before {
  color: var(--bs-blue) !important;
  font-size: 3.5vmax !important;

}

.react-multiple-carousel__arrow--left {
  background: transparent !important;

}