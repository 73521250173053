.mobile-topup {
  padding: 120px 0;
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 50%, #0288d1 100%);
  position: relative;
  overflow: hidden;
}

/* Add geometric background patterns */
.mobile-topup::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.05) 0%, transparent 20%),
    radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.05) 0%, transparent 20%);
  pointer-events: none;
}

.section-title {
  margin-bottom: 40px;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 800;
  color: #222;
  text-transform: uppercase;
}

.section-title p {
  font-size: 18px;
  color: #555;
}

.logos-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.single-logo {
  text-align: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.single-logo:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.logo {
  max-width: 100px;
  margin-bottom: 15px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.single-logo h3 {
  font-size: 24px;
  font-weight: 600;
  color: #222;
}

.djezzy {
  position: absolute;
  top: 0;
  left: 10%;
  animation: float 6s ease-in-out infinite;
}
.feature-icon-mobile-topup{
color:white
}
.ooredoo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: float 6s ease-in-out infinite;
}

.mobilis {
  position: absolute;
  bottom: 0;
  right: 10%;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.mobile-topup-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  position: relative;
  z-index: 2;
}

.content-left {
  flex: 1;
}

.content-left h2 {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 25px;
  background: linear-gradient(to right, #ffffff, #e3f2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 15px rgba(255, 255, 255, 0.1);
}

.content-left p {
  font-size: 20px;
  color: #e2e8f0;
  margin-bottom: 40px;
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 18px;
  color: #fff;
  padding: 12px 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.feature-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(10px);
}

.feature-icon {
  width: 24px;
  height: 24px;
  background: linear-gradient(135deg, #fff 0%, #e2e8f0 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
}

.content-right {
  flex: 1;
  position: relative;
}
.Services{
    color:white !important;
}
.section-subtitle {
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background: #f0f7ff;
    color: #2563eb;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }
.operators-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;  /* Reduced gap to accommodate 4 operators */
  position: relative;
  flex-wrap: wrap;
  padding: 30px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.operator {
  position: relative;
  padding: 15px;
  transition: all 0.3s ease;
  border-radius: 15px;
}

.operator img {
  width: 120px;
  height: auto;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.4));
  transition: all 0.5s ease;
}

.feature-text{
    color: #fff;
}

.idoom-operator img {
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3)) 
         brightness(1.1) 
         contrast(1.1);
}

.operator-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease;
}

.operator:hover {
  transform: translateY(-10px) scale(1.05);
  background: rgba(255, 255, 255, 0.15);
}

.operator:hover img {
  filter: drop-shadow(0 0 25px rgba(255, 255, 255, 0.6));
  transform: scale(1.1) rotate(5deg);
}

.bonus-feature {
  background: linear-gradient(135deg, rgba(255, 215, 0, 0.15), rgba(255, 255, 255, 0.05));
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 215, 0, 0.3);
  animation: glow 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.2),
                0 0 20px rgba(255, 215, 0, 0.1);
  }
  50% {
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.4),
                0 0 30px rgba(255, 215, 0, 0.2);
  }
}

@media (max-width: 991px) {
  .mobile-topup-content {
    flex-direction: column;
    text-align: center;
  }

  .features-list {
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.07);
  }

  .operators-wrapper {
    margin-top: 50px;
    flex-wrap: wrap;
    gap: 15px;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.07);
  }
  
  .operator img {
    width: 100px;  /* Slightly smaller on tablets */
  }
}

@media (max-width: 768px) {
  .content-left h2 {
    font-size: 36px;
  }

  .operator img {
    width: 80px;  /* Even smaller on mobile */
  }
}
