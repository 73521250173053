.footer {
    background-color: #081828;
    position: relative;
  }
  
  .footer .footer-top {
    padding: 100px 0;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .footer-top {
      padding: 80px 0;
      padding-top: 40px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .footer-top {
      padding: 60px 0;
      padding-top: 20px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .single-footer {
      margin-top: 40px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .single-footer {
      margin-top: 40px;
      text-align: center;
    }
  }
  
  .footer .single-footer.f-about {
    padding-right: 30px;
  }
  
  @media (max-width: 767px) {
    .footer .single-footer.f-about {
      padding: 0;
    }
  }
  
  .footer .single-footer.f-about .logo img {
    width: 180px;
  }
  
  .footer .single-footer.f-about p {
    color: #D2D6DC;
    margin-top: 20px;
    font-size: 14px;
  }
  
  .footer .single-footer.f-about .social {
    margin-top: 30px;
  }
  
  .footer .single-footer.f-about .social li {
    display: inline-block;
    margin-right: 17px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .single-footer.f-about .social li {
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .single-footer.f-about .social li {
      margin-bottom: 10px;
    }
  }
  
  .footer .single-footer.f-about .social li:last-child {
    margin: 0;
  }
  
  .footer .single-footer.f-about .social li button {
    color: #D2D6DC;
    font-size: 12px;
  }
  
  .footer .single-footer.f-about .social li button,a:hover {
    color: var(--bs-blue);
  }
  
  .footer .single-footer.f-about .social li:last-child {
    margin: 0;
  }
  
  .footer .single-footer.f-about .copyright-text {
    color: #D2D6DC;
    font-size: 14px;
    margin-top: 40px;
  }
  
  @media (max-width: 767px) {
    .footer .single-footer.f-about .copyright-text {
      margin-top: 20px;
    }
  }
  
  .footer .single-footer.f-about .copyright-text button,a {
    color: #D2D6DC;
  }
  
  .footer .single-footer.f-about .copyright-text button,a:hover {
    color:var(--bs-blue);
  }
  
  .footer .single-footer.f-link li {
    display: block;
    margin-bottom: 12px;
  }
  
  .footer .single-footer.f-link li:last-child {
    margin: 0;
  }
  
  .footer .single-footer.f-link li button {
    font-size: 14px;
    font-weight: 500;
    color: #D2D6DC;
    text-align: left;
  }
  .footer .single-footer.f-link li a {
    font-size: 14px;
    font-weight: 500;
    color: #D2D6DC;
    text-align: left;
  }
  .footer .img-ramadan{
    
    width: 80%;
    height: auto;
  }
  .footer .single-footer.f-linkArabic li button {
    text-align: right !important;
  }
  .footer .single-footer.f-linkArabic li a {
    text-align: right !important;
  }
  .footer .single-footer.f-link li button:hover {
    color: var(--bs-blue);
  }
  .footer .single-footer.f-link li a:hover {
    color: var(--bs-blue);
  }
  
  .footer .single-footer h3 {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 35px;
    color: #D2D6DC;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .single-footer h3 {
      margin-bottom: 25px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .single-footer h3 {
      margin-bottom: 25px;
    }
  }
  
  .footer .footer-newsletter {
    padding-bottom: 100px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .footer-newsletter {
      padding-bottom: 80px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter {
      padding-bottom: 60px;
      text-align: center;
    }
  }
  
  .footer .footer-newsletter .inner-content {
    border: 2px solid rgba(238, 238, 238, 0.171);
    padding: 50px;
    border-radius: 8px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .footer-newsletter .inner-content {
      padding: 30px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter .inner-content {
      padding: 30px;
    }
  }
  
  .footer .footer-newsletter .title {
    position: relative;
  }
  
  .footer .footer-newsletter .title h3 {
    color: #D2D6DC;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: capitalize;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .footer-newsletter .title h3 {
      font-size: 16px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter .title h3 {
      font-size: 16px;
    }
  }
  
  .footer .footer-newsletter .title p {
    font-size: 15px;
    color: #D2D6DC;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .footer-newsletter .title p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter .title p {
      font-size: 14px;
    }
  }
  
  .footer .footer-newsletter .title p button {
    color:var(--bs-blue);
    text-decoration: underline;
  }
  
  .footer .footer-newsletter .title p button:hover {
    color: #fff;
  }
  
  .footer .footer-newsletter .newsletter-form {
    position: relative;
  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter .newsletter-form {
      margin-top: 30px;
    }
  }
  
  .footer .footer-newsletter .newsletter-form input {
    height: 48px;
    width: 300px;
    display: inline-block;
    background: transparent;
    border: none;
    color: #fff;
    border-radius: 0;
    padding: 0 20px;
    color: #fff !important;
    font-size: 14px;
    background-color: #fff3;
    border-radius: 5px;
    float: right;
    margin-right: 148px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer .footer-newsletter .newsletter-form input {
      width: 100%;
      margin: 0;
      padding-right: 144px;
    }

  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter .newsletter-form input {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    .footer .img-ramadan{
    
      width: 30% ;
      height: auto;
    }
  }
  
  .footer .footer-newsletter .newsletter-form ::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
  }
  
  .footer .footer-newsletter .newsletter-form :-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
  }
  
  .footer .footer-newsletter .newsletter-form ::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
  }
  
  .footer .footer-newsletter .newsletter-form ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
  }
  
  .footer .footer-newsletter .newsletter-form :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }
  
  .footer .footer-newsletter .newsletter-form ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
  
  .footer .footer-newsletter .newsletter-form .button {
    display: inline-block;
    position: absolute;
    right: 0;
  }
  
  @media (max-width: 767px) {
    .footer .footer-newsletter .newsletter-form .button {
      position: relative;
      margin-top: 10px;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    .footer .footer-newsletter .newsletter-form .button .btn {
      width: 100%;
    }
  }
  
  .footer .footer-newsletter .newsletter-form .button .btn {
    display: inline-block;
    background: var(--bs-blue);
    color: #fff;
    height: 48px;
    line-height: 48px;
    padding: 0 30px;
  }
  
  .footer .footer-newsletter .newsletter-form .button .btn::before {
    background-color: #fff;
  }
  
  .footer .footer-newsletter .newsletter-form .button .btn:hover {
    color:var(--bs-blue);
    background-color: #fff;
  }
  button {
    /* أنماط التصميم الخاصة بك هنا */
    border: none;
    background-color: transparent;
    color: transparent;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  .footer a {
    /* أنماط التصميم الخاصة بك هنا */
    border: none;
    background-color: transparent;
    color: #D2D6DC;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  