.ud-testimonials {
  padding-top: 120px;
}

@media (max-width: 767px) {
  .ud-testimonials {
    padding-top: 80px;
  }
}

.ud-section-title-Testimonial span {
  color: var(--bs-blue);
}

.ud-single-testimonial {
  padding: 30px;
  -webkit-box-shadow: 0px 60px 120px -20px #ebeffd;
  box-shadow: 0px 60px 120px -20px #ebeffd;
  background: white;
  min-height: 300px;
  /* ارتفاع العنصر الأدنى */
  max-height: 400px;
  margin-bottom: 50px;
}

.ud-single-testimonial .ud-testimonial-ratings {
  margin-bottom: 10px;
}

.ud-single-testimonial .ud-testimonial-ratings i {
  color: #fbb040;
}

.ud-single-testimonial .ud-testimonial-content {
  height: 180px;
}

.ud-single-testimonial .ud-testimonial-content p {
  font-size: 16px;
  line-height: 30px;
  color: #637381;
}

.ud-single-testimonial .ud-testimonial-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 90px;

}

.ud-single-testimonial .ud-testimonial-info .ud-testimonial-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.ud-single-testimonial .ud-testimonial-info .ud-testimonial-meta h4 {
  font-weight: 600;
  font-size: 14px;
}

.ud-single-testimonial .ud-testimonial-info .ud-testimonial-meta p {
  font-size: 12px;
  color: #969696;
}

/* ===== Team CSS ===== */
.ud-team {
  padding-top: 120px;
  padding-bottom: 80px;
}

@media (max-width: 767px) {
  .ud-team {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

.ud-single-team {
  margin-bottom: 40px;
}

.ud-single-team .ud-team-image-wrapper {
  width: 170px;
  height: 170px;
  position: relative;
  margin: 0px auto 25px;
}

.ud-single-team .ud-team-image {
  border-radius: 50%;
}

.ud-single-team .ud-team-image img {
  width: 100%;
  border-radius: 50%;
}

.ud-single-team .shape {
  position: absolute;
  z-index: -1;
}

.ud-single-team .shape.shape-1 {
  top: 0;
  left: 0;
}

.ud-single-team .shape.shape-2 {
  bottom: 0;
  right: 0;
}

.ud-single-team .ud-team-info {
  text-align: center;
  margin-bottom: 20px;
}

.ud-single-team .ud-team-info h5 {
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.ud-single-team .ud-team-info h6 {
  font-weight: 500;
  font-size: 12px;
  color: grey;
}

.ud-single-team .ud-team-socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ud-single-team .ud-team-socials a {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px 10px;
  color: #cdced6;
}

.ud-single-team .ud-team-socials a:hover {
  color: blue;
}