.TransactionTable {
    background: #f3f4fe;
}
.TransactionTable .container{
    margin-top: 40px;
    padding-top: 40px;
    margin-bottom: 20px;

}

.tableContant {
    padding-top: 35px;
    padding-right: 50px;
    padding-left: 100px;
    padding-bottom: 80px;
    border-radius: 10px;
    width: calc(100% - 40px);
}
@media screen and (min-width: 1200px) {
    .StyledTable {
        max-width: 80%; /* Adjust as needed */
        margin: 0 auto; /* Center the table horizontally */
    }
}
.StyledTable button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
    appearance: none;
}
.StyledTable {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
.table {
    display: flex;
    flex-direction: column;
    

  }
  th, td {
    flex: 1;
  }
  @media screen and (max-width: 768px) {
    .table {
      flex-direction: row;
    }
  }
.StyledTable thead {
    background-color: var(--bs-blue);
    color: white;
    font-weight: bold;
    text-align: center;
    
}

.StyledTable thead th {
    padding: 8px;
    border: 1px solid #ddd;
}

.StyledTable tbody tr:nth-child(even) {
    background-color: #f7f7f7;
}

.StyledTable tbody td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
}