.intro-video-area {
    background-color: #081828;
    position: relative;
    z-index: 9;
    padding-bottom: 0;
    padding-top: 130px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .intro-video-area {
      padding-top: 90px;
    }
  }
  
  @media (max-width: 767px) {
    .intro-video-area {
      padding-top: 60px;
    }
  }
  
  .intro-video-area .ud-section-title-InfroVedio {
    margin-bottom: 150px;
    padding: 0 265px;
  }
    
  .intro-video-area .ud-section-title-InfroVedio span {

    color: var(--bs-blue);
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .intro-video-area .ud-section-title-InfroVedio {
      padding: 0 50px;
      margin-bottom: 120px;
    }
  }
  
  @media (max-width: 767px) {
    .intro-video-area .ud-section-title-InfroVedio {
      padding: 0 30px;
      margin-bottom: 100px;
    }
    .intro-video-area .section-title h2 {
      font-size: 20px;
    }
  }
  
  .intro-video-area .section-title span {
    display: block;
    color: var(--bs-blue);
  }
  
  @media (max-width: 767px) {
   
  }
  
  .intro-video-area::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 150px;
    width: 100%;
    background-color: #fff;
    z-index: -1;
  }
  
  .intro-video-area .inner-content-head {
    padding: 30px;
    border: 1px solid #eeeeee40;
    border-radius: 30px;
    background: #ffffff24;
  }
  
  @media (max-width: 767px) {
    .intro-video-area .inner-content-head {
      padding: 0;
      border: none;
    }
  }
  
  .intro-video-area .inner-content {
    background-color: #fff;
    padding: 100px 0;
    height: 100%;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.09);
    position: relative;
    overflow: hidden;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .intro-video-area .inner-content {
      padding: 50px 0;
      padding-bottom: 80px;
    }
  }
  
  @media (max-width: 767px) {
    .intro-video-area .inner-content {
      padding: 50px 0;
    }
  }
  
  .intro-video-area .inner-content .shape1 {
    height: 120px;
    width: 120px;
    position: absolute;
    right: -60px;
    bottom: 58px;
  }
  
  @media (max-width: 767px) {
    .intro-video-area .inner-content .shape1 {
      width: 80px;
      height: 80px;
      right: -50px;
      bottom: 20px;
    }
  }
  
  .intro-video-area .inner-content .shape2 {
    height: 100px;
    width: 100px;
    position: absolute;
    left: -10px;
    top: 30px;
  }
  
  @media (max-width: 767px) {
    .intro-video-area .inner-content .shape2 {
      width: 80px;
      height: 80px;
    }
  }
  
  .intro-video-area .intro-video-play {
    position: relative;
    z-index: 9;
  }
  
  .intro-video-area .intro-video-play .play-thumb a {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 83px;
    background: var(--bs-blue);
    border-radius: 50%;
    padding-left: 5px;
    font-size: 22px;
    color: #fff;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .intro-video-area .intro-video-play .play-thumb a {
      height: 70px;
      width: 70px;
      font-size: 20px;
      line-height: 70px;
    }
  }
  
  @media (max-width: 767px) {
    .intro-video-area .intro-video-play .play-thumb a {
      height: 60px;
      width: 60px;
      line-height: 60px;
      font-size: 18px;
    }
  }
  
  .intro-video-area .intro-video-play .play-thumb a:hover {
    background-color: #081828;
    color: #fff;
  }
  
  .intro-video-area .intro-video-play .play-thumb a::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid var(--bs-blue);
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1.5s linear infinite;
    animation: pulse-border-2 1.5s linear infinite;
  }
  
  .intro-video-area .intro-video-play .play-thumb a::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-animation: pulse-border 1s linear infinite;
    animation: pulse-border 1s linear infinite;
  }
  
  @-webkit-keyframes pulse-border {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      opacity: 0;
    }
  }
  
  @keyframes pulse-border {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes pulse-border-2 {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  @keyframes pulse-border-2 {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
  