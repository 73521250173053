/*======================================
	Start Header CSS
========================================*/
.header {
  width: 100%;
  background: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header {
    padding: 18px 0 !important;
  }

  .header .mobile-menu-btn .toggler-icon {
    background-color: #fff;
  }

  .header .button {
    margin: 0 !important;
  }


  .header .navbar-collapse {
    position: absolute;
    top: 67px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }

  .header .navbar .navbar-nav .nav-item a:hover {
    color: var(--bs-blue) !important;
   
    }

  .header .navbar .navbar-nav .nav-item a.active {
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item:hover a {
    color: var(--bs-blue);
  }

  .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
  }

  .header .navbar-nav .nav-item a::before {
    display: none;
  }

  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: var(--bs-blue) !important;
    
  }

  .header .navbar-nav .nav-item a {
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible;
  }

  .header .navbar-nav .nav-item .sub-menu li.active {
    background: #fff !important;
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 10px 12px !important;
  }

  .header .navbar-nav .nav-item .sub-menu li:hover {
    background: #fff !important;
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item a {
    font-size: 14px;
  }

  .header .navbar-nav .nav-item a:hover {
    color: var(--bs-blue);
  }
}

@media (max-width: 767px) {
  .header {
    padding: 18px 0 !important;
  }

  .header .mobile-menu-btn .toggler-icon {
    background-color: #fff;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 58px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }

  .header .navbar .navbar-nav .nav-item a:hover {
    color: var(--bs-blue) !important;
  }

  .header .navbar .navbar-nav .nav-item a.active {
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item:hover a {
    color: var(--bs-blue);
  }

  .header .navbar-nav .nav-item select {
    padding: 12px 16px !important;
    font-family: "Inter",  !important;

  }

  .header .navbar-nav .nav-item select::before {
    display: none;
  }

  .header .navbar .navbar-nav .nav-item select:hover {
    color: var(--bs-blue) !important;
  }

  .header .navbar .navbar-nav .nav-item select.active {
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item:hover select {
    color: var(--bs-blue);
  }
  .header .navbar-nav .nav-item:hover select option {
    color: #081828;
  }

  .header .navbar-nav .nav-item select {
    padding: 1px 2px !important;
    background-color: transparent ;
    border: none;
    font: optional;
    color: #081828;
    display: inline-block;
    text-decoration: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    font-family: "Inter", sans-serif;

  }
  

  .header .navbar-nav .nav-item select::before {
    display: none;
  }

  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item a {
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible;
  }
  .header .navbar-nav .nav-item select::after {
    opacity: 1;
    visibility: visible;
  }
  .header .navbar-nav .nav-item .sub-menu li.active {
    background: #fff !important;
    color: var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 10px 12px !important;
  }

  .header .navbar-nav .nav-item .sub-menu li:hover {
    background: #fff !important;
    color:var(--bs-blue) !important;
  }

  .header .navbar-nav .nav-item a {
    font-size: 14px;
  }

  .header .navbar-nav .nav-item a:hover {
    color: var(--bs-blue);
  }
}

@media (max-width: 767px) {
  .header .button {
    display: none;
  }


}


.header .button .btn {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 12px 30px;
}

.header .button .btn:hover {
  background-color: #fff;
  color: var(--bs-blue);
}

.header .navbar-brand img {
  width: 160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .navbar-brand img {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .header .navbar-brand img {
    width: 160px;
  }
}

.header.sticky .navbar-brand .white-logo {
  opacity: 0;
  visibility: hidden;
}

.header.sticky .navbar-brand .dark-logo {
  opacity: 1;
  visibility: visible;
}

.header.sticky .button .btn {
  background-color: var(--bs-blue);
  color: #fff;
  border-color: transparent;
}

.header.sticky .button .btn:hover {
  background-color: #081828;
  color: #fff;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  top: 0;
}

.navbar-expand-lg .navbar-nav {
  margin: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}

.header .navbar .navbar-nav .nav-item a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item a.active {
  color: var(--bs-blue);
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #081828;
}

.header .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333;
}


/*===== NAVBAR =====*/
.navbar-area {
  width: 100%;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0;
}
.navbar-area.sticky {
  position: fixed;
  z-index: 99;
  background: rgba(255, 255, 255, 0.244); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Blurring the background */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0px 0;
}
.navbar {
  padding: 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding-left: 0;
  border-radius: 0;
}

.mobile-menu-btn {
  padding: 0px;
}

.mobile-menu-btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mobile-menu-btn .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #222;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 8px;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

.navbar-nav .nav-item {
  z-index: 1;
  position: relative;
  margin-right: 40px;
}

.navbar-nav .nav-item:last-child {
  margin-right: 0 !important;
}

.navbar-nav .nav-item:hover a {
  color: #fff;
}

.sticky .navbar-nav .nav-item:hover a {
  color: var(--bs-blue);
}

.navbar-nav .nav-item a {
  font-size: 15px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  padding: 35px 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s ease-out 0s;
  position: relative;
  text-transform: capitalize;
}

.navbar-nav .nav-item a::after {
  opacity: 0;
  visibility: hidden;
}

.navbar-nav .nav-item:hover a:before {
  width: 100%;
}

.navbar-nav .nav-item a.active {
  color: #fff;
}

.navbar-nav .nav-item a.dd-menu::after {
  content: "\ea58";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 17px;
  font-size: 10px;
  top: 50%;
  margin-left: 5px;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 10px;
}
.navbar-nav .nav-item a.dde-menu::after {
  content: "\ea58";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 17px;
  font-size: 10px;
  top: 50%;
  margin-left: 5px;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu::after {
    right: 13px;
  } 
  .navbar-nav .nav-item a.dde-menu::after {
    right: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item a.dd-menu::after {
    top: 18px;
    right: 0px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  } 
  .navbar-nav .nav-item a.dde-menu::after {
    top: 18px;
    right: 345px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
 

}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item a.collapsed::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.navbar-nav .nav-item:hover>.sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.navbar-nav .nav-item:hover>.sub-menu .sub-menu {
  left: 100%;
  top: 0;
}

.navbar-nav .nav-item .sub-menu {
  padding: 10px;
  min-width: 50px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 20px #0000001a;
  box-shadow: 0px 5px 20px #0000001a;
  position: absolute;
  top: 110% !important;
  right: -30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0 0 4px 4px;
  border-radius: 5px;
}

.navbar-nav .nav-item:hover .sub-menu {
  top: 100% !important;
}

.navbar-nav .nav-item .sub-menu .nav-item {
  width: 100%;
  margin-bottom: 10px;
}

.navbar-nav .nav-item .sub-menu .nav-item:last-child {
  margin: 0;
}

.navbar-nav .nav-item .sub-menu .nav-item a {
  padding: 0;
  color: #888;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.navbar-nav .nav-item .sub-menu .nav-item a:hover {
  color:var(--bs-blue);
}

.navbar-nav .nav-item .sub-menu.left-menu {
  left: -100%;
}

.navbar-nav .nav-item .sub-menu.collapse:not(.show) {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
}

.navbar-nav .nav-item .sub-menu>li {
  display: block;
  margin-left: 0;
}

.navbar-nav .nav-item .sub-menu>li:last-child {
  border: none;
}

.navbar-nav .nav-item .sub-menu>li.active>a {
  color:var(--bs-blue) !important;
}

.navbar-nav .nav-item .sub-menu>li>a {
  font-weight: 400;
  display: block;
  padding: 12px 15px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}

.navbar-nav .nav-item .sub-menu>li:first-child a {
  border: none;
  
}

.add-list-button {
  display: inline-block;
  margin-left: 10px;
}

.add-list-button .btn i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
}

.language-switcher {
  border-radius: 10%;
}

.language-switcher select {
  padding: 8px;
  font-size: 18px;
  background-color: transparent !important;
  border: none;
  color: #fff;
  /* Default text color */
  appearance: none;
  /* Disable default appearance */
}

.language-switcher select option {
  color: black;
  background-color: transparent !important;
}

.language-switcher select:focus {
  outline: none;
  /* Remove the outline on focus if you don't want it */
}

.language-switcher.scrolled select {
  color: black !important;
}


.language-item {
  display: none;
}

/* يختفي عندما تكون شاشة العرض أقل من أو تساوي 767 بكسل */
@media (max-width: 767px) {
  .language-item {
      display: block;
  }
}
/* Style for the select element */
.select-style {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: var(--bs-blue);
  color: #fff;
  border: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  margin-right: 7px;
  cursor: pointer;
  overflow: hidden;
}

/* Apply the styles on hover */
.select-style:hover {
  background-color: #081828;
}

/* Apply the styles for the dropdown arrow */
.select-style::after {
  content: '\25BC';
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

/* Apply the styles for the dropdown options */
.select-style select {
  background-color: transparent;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #fff;
  cursor: pointer;
}

/* Apply styles for the option on hover/focus */
.select-style select:hover,
.select-style select:focus {
  outline: none;
}
.language-switcher select option{
  border: 2px solid #ccc !important;
  border-radius:8px;
  background-color: #888;

}

.li-language a{
  text-align: right !important;
}

