.error-area {
    height: 100vh;
    text-align: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    position: relative;
  }
  
  .d-table {
    width: 100%;
    height: 100%;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-cell {
    vertical-align: middle;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .error-area .error-content h1 {
    font-size: 100px;
    color: var(--bs-blue);
    margin-bottom: 25px;
    font-weight: 800;
    line-height: 100px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .error-area .error-content h1 {
      font-size: 60px;
      line-height: 50px;
    }
  }
  
  @media (max-width: 767px) {
    .error-area .error-content h1 {
      font-size: 45px;
      line-height: 30px;
    }
  }
  
  .error-area .error-content h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #081828;
    font-weight: 700;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .error-area .error-content h2 {
      font-size: 22px;
    }
  }
  
  @media (max-width: 767px) {
    .error-area .error-content h2 {
      font-size: 18px;
    }
  }
  
  .error-area .error-content p {
    font-weight: 400;
    margin-bottom: 40px;
    color: #888;
  }