.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto
}

.select {
  cursor: pointer;
  position: absolute;
  bottom: -15px;
}

#btn-c:hover {
  background-color: black !important;
  color: white !important;
}

.select-left {
  text-align: right;
  margin-right: 35px;
}

.select-right {
  text-align: left;
  margin-left: 50px;
}


@media (min-width:576px) {

  .container,
  .container-sm {
    max-width: 540px
  }
}

@media (min-width:768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px
  }
}

@media (min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px
  }
}

@media (min-width:1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px
  }
}

@media (min-width:1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px
  }
}

.hero-area {
  position: relative;
  padding: 140px 0 190px 0;
  background: radial-gradient(at left top, #0e6cff, transparent), radial-gradient(at right top, #0c2a96, transparent),
    radial-gradient(at left bottom, #02b8f5, transparent);
  backdrop-filter: blur(200px);


}

/* في ملف HeroArea.css */

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(at left top, #0e6cff, transparent), radial-gradient(at right top, #0c2a96, transparent),
    radial-gradient(at left bottom, #02b8f5, transparent);
  display: none;
  /* يكون مخفياً بشكل افتراضي */
}

.modal-backdrop.show {
  backdrop-filter: blur(4px);
  /* قيمة الـ blur التي تناسب التصميم */
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 300px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.hero-area .hero-image {
  top: -200px;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding: 150px 0 60px 0;
  }

  .hero-area .hero-image {
    height: 500px;
    top: 30px;
  }

}

@media (max-width: 767px) {
  .hero-area {
    padding: 120px 0 50px 0;
  }

  .hero-area .hero-image {
    height: 350px;
    top: 30px;
  }
}



.phone1,
.phone2 {

  transition: none;
}

.parallax-enabled .phone1 {
  transform: translateX(100px);
  /* Adjust the value as needed */
}

.parallax-enabled .phone2 {
  transform: translateX(-100px);
  /* Adjust the value as needed */
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-image {
    margin-top: 40px;
  }

  html{
    overflow-y: scroll !important
  }
}

@media (max-width: 767px) {
  .hero-area .hero-image {
    margin-top: 40px;
  }
}

.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  z-index: 1;
  text-align: left;
}

.hero-area .hero-content h1 {
  font-size: 38px;
  font-weight: 800;
  line-height: 50px;
  color: #fff;
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
}

.hero-area .hero-content h1 span {
  display: block;
}

.hero-area .hero-content p {
  margin-top: 30px;
  font-size: 15px;
  color: #fff;
}

.hero-area .hero-content .button {
  margin-top: 40px;
}

.hero-area .hero-content .button .btn {
  background-color: #fff;
  color: var(--bs-blue);
  margin-right: 12px;
}

.hero-area .hero-content .button .btn i {
  font-size: 17px;
}



.hero-area .hero-content .button .btn.btn-alt {
  background-color: #ffffff6b;
  color: #fff;
}

.hero-area .hero-content .button .btn.btn-alt:hover {
  background-color: #fff;
  color: var(--bs-blue);
}

@media (max-width: 767px) {
  .hero-area .hero-content .button .video-button {
    margin-top: 20px;
  }
}

.hero-area .hero-content .button .video-button .text {
  display: inline-block;
  margin-left: 15px;
  color: #fff;
  font-weight: 500;
}

.hero-area .hero-content .button .video-button:hover .video {
  color: #fff;
  background-color: #081828;
}

.hero-area .hero-content .button .video-button .video {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center !important;
  color: var(--bs-blue);
  background-color: #fff;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 16px;
  padding-left: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative;
}

.hero-area .hero-content .button .video-button .video:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 2s linear infinite;
  animation: pulse-border-2 2s linear infinite;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
  }


}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center !important;
  }

  .select {
    text-align: center !important;
    position: static !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    text-align: center !important;

  }

  .hero-area .hero-content p {
    font-size: 15px;
    text-align: center !important;

  }

  .hero-area .hero-content .button {
    text-align: center !important;

  }

}

@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
    text-align: center !important;
  }

  .modal-dialog {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .hero-area .hero-content h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: center !important;

  }

  .select {
    text-align: center !important;
    position: static !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
    text-align: center !important;

  }

  .hero-area .hero-content .button {
    text-align: center !important;

  }

  .hero-area .hero-content .button .btn {
    width: 60%;
    margin: 0;
    margin-bottom: 7px;
    text-align: center !important;

  }

  .hero-area .hero-content .button .btn:last-child {
    margin: 0;
    text-align: center !important;

  }
}