/* ===== Contact CSS ===== */
.ud-contact {
  padding: 130px 0px;
  position: relative;
}

@media (max-width: 767px) {
  .ud-contact {
    padding: 80px 0;
  }
}

.ud-contact::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  left: 0;
  height: 50%;
  background: #f3f4fe;
}
.CONTANT h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.CONTANT h1 {
  font-size: 2em;
}

.CONTANT h2 {
  font-size: 1.5em;
}

.CONTANT h3 {
  font-size: 1.25em;
}

.CONTANT h4 {
  font-size: 1em;
}

.CONTANT h5 {
  font-size: 0.875em;
}

.CONTANT h6 {
  font-size: 0.75em;
}

.CONTANT p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.CONTANT a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px dotted #000;
}

.CONTANT a:hover {
  text-decoration: underline;
}

.CONTANT code {
  font-family: monospace;
  background-color: #f6f8fa;
  padding: 2px 4px;
  border-radius: 4px;
}

pre {
  font-family: monospace;
  background-color: #f6f8fa;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
}

img {
  max-width: 100%;
  margin: 10px 0;
}

.CONTANT ul, ol {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}

.CONTANT ul li, ol li {
  margin-bottom: 5px;
}

.CONTANT blockquote {
  margin: 10px 0;
  padding: 10px;
  border-left: 4px solid #ccc;
}

.CONTANT hr {
  border: 0;
  border-top: 1px solid #ccc;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ud-contact::after {
    height: 45%;
  }
}

.ud-contact-title {
  margin-bottom: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .ud-contact-title {
    margin-bottom: 50px;
  }

  .mycard {
    background: #fbfbfb;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #cbcbcb 0px 5px 20px 3px;
    width: calc(100% - 40px);
    margin: 20px;
  }

  .mycard span {
    font-size: 20px;
  }
}


.mycard {
  background: #fbfbfb;
  padding-top: 35px;
  padding-right: 50px;
  padding-left: 100px;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: #cbcbcb 0px 5px 20px 3px;
  width: calc(100% - 40px);
  margin: 20px;
}

.mycard h2 {

  font-size: calc(1.375rem + 1.5vw);
}

.mycard span {
  color: #868686;
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 1.5;
  /* يمكن تعديل هذه القيمة حسب الحاجة */

}

span {
  color: #212b36;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 20px;
}

.ud-contact-title h2 {
  font-weight: 600;
  font-size: 35px;
  line-height: 41px;
}

.ud-contact-info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .ud-contact-info-wrapper {
    margin-bottom: 50px;
  }

  .mycard {
    background: #fbfbfb;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #cbcbcb 0px 5px 20px 3px;
    width: calc(100% - 40px);
    margin: 20px;
  }

  .mycard span {
    font-size: 20px;
  }
}

.ud-contact-info-wrapper .ud-single-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  width: 330px;
  margin-bottom: 30px;
}

.ud-contact-info-wrapper .ud-info-icon {
  font-size: 32px;
  color: var(--bs-blue);
  margin-right: 24px;
}

.ud-contact-info-wrapper .ud-info-meta h5 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}

.ud-contact-info-wrapper .ud-info-meta p {
  font-size: 15px;
  line-height: 24px;
}

.ud-contact-form-wrapper {
  -webkit-box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: white;
  padding: 60px;
}

@media (max-width: 767px) {
  .ud-contact-form-wrapper {
    padding: 40px 30px;
  }

  .mycard {
    background: #fbfbfb;
    padding: 15px;
    border-radius: 10px;

    box-shadow: #cbcbcb 0px 5px 20px 3px;
    width: calc(100% - 40px);
    margin: 20px;
  }

  .mycard span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .ud-contact-form-wrapper {
    padding: 50px 40px;
  }

}

.ud-contact-form-wrapper .ud-contact-form-title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .ud-contact-form-wrapper .ud-contact-form-title {
    font-size: 24px;
  }

  .mycard {
    background: #fbfbfb;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #cbcbcb 0px 5px 20px 3px;
    width: calc(100% - 40px);
    margin: 20px;
  }

  .mycard span {
    font-size: 20px;
  }
}

.ud-contact-form-wrapper .ud-form-group {
  margin-bottom: 25px;
}

.ud-contact-form-wrapper .ud-form-group label {
  display: block;
  font-weight: normal;
  font-size: 12px;
}

.ud-main-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  padding: 15px 25px;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background: var(--bs-blue);
}

.ud-main-btn:hover {
  color: white;
  background: #212b36;
}

.ud-contact-form-wrapper .ud-form-group input,
.ud-contact-form-wrapper .ud-form-group textarea {
  display: block;
  width: 100%;
  padding: 15px 0;
  border: none;
  outline: none;
  border-bottom: 1px solid #f1f1f1;
  resize: none;
}

.ud-contact-form-wrapper .ud-form-group input:focus,
.ud-contact-form-wrapper .ud-form-group textarea:focus {
  border-color: var(--bs-blue);
}