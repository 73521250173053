.bill-payment {
  background: #ffffff;
  padding: 80px 0;
  position: relative;
}

.background-decoration {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(56, 189, 248, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(14, 165, 233, 0.1) 0%, transparent 50%);
  pointer-events: none;
}

.bill-payment-content {
  position: relative;
  z-index: 2;
}

.bill-payment-cards {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0 20px;
}

.bill-payment-info {
  flex: 1;
}

.bill-payment-info h2 {
  font-size: 40px;
  color: #1a1a1a;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.bill-payment-info p {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
  line-height: 1.6;
}

.pay-now-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 16px 32px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(37, 99, 235, 0.1);
}

.pay-now-button:hover {
  background: #1d4ed8;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}

.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.pay-now-button:hover .arrow-icon {
  transform: translateX(4px);
}

.providers-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.provider-box {
  background: #f8fafc;
  border-radius: 16px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
}

.provider-box img {
  width: 120px;
  height: auto;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease;
}

.provider-hover-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(37, 99, 235, 0.05), rgba(37, 99, 235, 0.1));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.provider-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border-color: #2563eb;
}

.provider-box:hover img {
  transform: scale(1.1);
}

.provider-box:hover .provider-hover-effect {
  opacity: 1;
}

@media (max-width: 991px) {
  .bill-payment-cards {
    flex-direction: column;
    text-align: center;
  }

  .bill-payment-info h2 {
    font-size: 32px;
  }

  .providers-grid {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .providers-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .bill-payment-info h2 {
    font-size: 28px;
  }

  .provider-box img {
    width: 100px;
  }
}

.bill-payment-content {
  position: relative;
  z-index: 2;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.text-content {
  flex: 1;
}

.text-content h2 {
  font-size: 44px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 24px;
  line-height: 1.2;
  background: linear-gradient(to right, #fff, #94a3b8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-content p {
  font-size: 18px;
  color: #cbd5e1;
  margin-bottom: 40px;
  line-height: 1.6;
}

.pay-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 16px 32px;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(59, 130, 246, 0.3);
}

.pay-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(59, 130, 246, 0.4);
}

.button-arrow {
  transition: transform 0.3s ease;
}

.pay-button:hover .button-arrow {
  transform: translateX(5px);
}

.providers-showcase {
  display: flex;
  gap: 30px;
  flex: 1;
}

.provider-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 30px;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.provider-card:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.1);
}

.provider-logo {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.provider-logo img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.2));
  transition: all 0.3s ease;
}

.provider-card:hover .provider-logo img {
  transform: scale(1.1);
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.3));
}

.provider-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(59, 130, 246, 0.2) 0%, transparent 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.provider-card:hover .provider-glow {
  opacity: 1;
}

.modern-grid-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

  grid-template-areas:
    "left right"
    "main main";
  gap: 40px;
  position: relative;
}

.logo-left, .logo-right {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 30px;
  transition: transform 0.3s ease;
  cursor: pointer;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
}

.logo-left {
  grid-area: left;
}

.logo-right {
  grid-area: right;
}

.logo-left:hover, .logo-right:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.08);
}

.service-logo {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2));
}

.logo-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
  background: linear-gradient(to right, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-content p {
  color: #4b5563;
  font-size: 16px;
}

.main-content {
  grid-area: main;
  text-align: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
}

.main-content h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(to right, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-description {
  color: #4b5563;
  font-size: 20px;
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #374151;
}

.feature-icon {
  width: 24px;
  height: 24px;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.dual-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin-bottom: 60px;
  background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.brand-logo {
  flex: 0 0 120px;
}

.brand-logo img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3));
  transition: transform 0.3s ease;
}

.brand-logo:hover img {
  transform: scale(1.1);
}

.header-content {
  text-align: center;
  color: #1f2937;
  flex: 1;
  padding: 0 20px;
}

.header-content h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #1f2937;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content p {
  font-size: 18px;
  color: #4b5563;
  opacity: 0.9;
}

@media (max-width: 991px) {
  .content-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .providers-showcase {
    justify-content: center;
  }

  .text-content h2 {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .modern-grid-container {
    grid-template-areas:
      "left"
      "right"
      "main";
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .main-content h2 {
    font-size: 36px;
  }

  .dual-header {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .brand-logo {
    flex: 0 0 80px;
  }

  .header-content h2 {
    font-size: 24px;
  }

  .header-content p {
    font-size: 16px;
  }

  .providers-showcase {
    flex-direction: column;
    align-items: center;
  }

  .provider-card {
    width: 100%;
    max-width: 280px;
  }
}
