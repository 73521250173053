.game-services {
  position: relative;
  padding: 100px 0;
  background: url('../../../../assets/images/service/background.jpg') no-repeat center center/cover;
  color: #fff;
}

.game-services .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.title-text{
    color: white !important;
}
.section-title {
  margin-bottom: 60px;
  position: relative;
  z-index: 2;
}
.section-title .text-center h2{
    color: white !important;
}
.section-title h2 {
  font-size: 40px;
  font-weight: 800;
  color: white;  /* Changed to white */
  text-transform: uppercase;
}

.section-title p {
  font-size: 20px;
  color: white;  /* Changed to white */
}

.single-service {
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 2;
}

.single-service:hover {
  transform: translateY(-15px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
}

.service-image {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  margin: 0 auto 25px;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.single-service:hover .service-image img {
  transform: scale(1.2);
}

.single-service h3 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.single-service p {
  font-size: 18px;
  color: #ddd;
}

.payment-method-info {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 15px;
  margin-top: 40px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.payment-method-info h3 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 15px;
}

.payment-method-info p {
  color: #e2e8f0;
  font-size: 16px;
  line-height: 1.6;
}