.features {
    background-color: #f9f9f9;
  }
  
  .features .section-title {
    margin-bottom: 30px;
  }
  .ud-section-title-Features span{
    color: var(--bs-blue);
  }
  .features .single-feature {
    text-align: left;
    padding: 35px;
    border-radius: 6px;
    height: 350px;
    position: relative;
    margin-top: 30px;
    border: 1px solid #eee;
    -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  }
  
  .features .single-feature:hover {
    -webkit-box-shadow: 0px 3px 5px #00000017;
            box-shadow: 0px 3px 5px #00000017;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  
  .features .single-feature a {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    display: inline-block;
    background-color: var(--bs-white);
    color: var(--bs-blue);
    font-size: 20px;
    border-radius: 7px;
    -webkit-box-shadow: 0px 4px 6px #0000002a;
            box-shadow: 0px 4px 6px #0000002a;
    margin-bottom: 30px;
  }
  
  
  .features .single-feature h3 {
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .features .single-feature p {
    color: #eee;
    line-height: 22px;
  }
  
 .features .single-feature .btn {
  margin-top: 20px;
  padding: 9px 20px;
  background-color: transparent;
    color: var(--bs-white) !important;
    border:  solid 2px var(--bs-white) !important ;
    margin-right: 12px;

   }
   .features  .btn {
    margin-top: 20px;
    padding: 9px 20px;
    background-color: transparent;
      color: var(--bs-blue) !important;
      border:  solid 2px var(--bs-blue) !important ;
      margin-right: 12px;
  
     }
  
  
   .features .btn:hover {
    background-color: #081828 !important;
    color: #fff !important;
    border:  solid 2px #081828 !important ;

  }
  .feature-number {
    display: inline-block;
    width: 30px; /* Adjust as needed */
    text-align: center;
    font-size: 18px; /* Adjust as needed */
  }
  
  .material-symbols-outlined{
    font-size: 45px;
  }
  

  
  