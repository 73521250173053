
/* ===== FAQ CSS ===== */
.ud-faq {
    padding-top: 120px;
    padding-bottom: 90px;
    background: #f3f4fe;
    position: relative;
    z-index: 1;
  }
  
  @media (max-width: 767px) {
    .ud-faq {
      padding-top: 80px;
      padding-bottom: 50px;
    }
  }
  
  .ud-faq .shape {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
  }
  
  .ud-single-faq {
    background: white;
    border: 1px solid #f3f4fe;
    -webkit-box-shadow: 0px 20px 95px rgba(201, 203, 204, 0.3);
            box-shadow: 0px 20px 95px rgba(201, 203, 204, 0.3);
    border-radius: 10px;
    margin-bottom: 30px;
  }
  
  .ud-single-faq .ud-faq-btn {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: none;
    background: transparent;
    font-weight: 600;
    font-size: 18px;
    color: grey;
    opacity: 0.88;
    padding: 30px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
    .ud-single-faq .ud-faq-btn {
      padding: 15px;
    }
  }
  
  .ud-single-faq .ud-faq-btn.collapsed span.icon i {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  
  .ud-single-faq .ud-faq-btn span.icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 42px;
    height: 42px;
    background: rgba(48, 86, 211, 0.06);
    border-radius: 10px;
    color: var(--bs-blue);
    margin-right: 24px;
  }
  .ud-section-title-Faq span{
    color:  var(--bs-blue);
  }
  .ud-single-faq .ud-faq-btn span.icon i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: 700;
  }
  
  .ud-single-faq .ud-faq-body {
    padding: 0px 30px 40px 95px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
    .ud-single-faq .ud-faq-body {
      padding: 0px 16px 20px 82px;
    }
  }
  