
.ud-brands .ud-title {
    margin-bottom: 30px;
  }
  
  .ud-brands .ud-title h6 {
    font-weight: normal;
    font-size: 12px;
    color: var(--body-color);
    display: inline-block;
    position: relative;
  }
  
  .ud-brands .ud-title h6::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    background: #afb2b5;
    right: -40px;
    top: 50%;
  }
  
  .ud-brands .ud-brands-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: -40px;
  }
  
  .ud-brands .ud-brands-logo .ud-single-logo {
    margin-bottom: 50px;
    margin-left: 30px;
    max-width: 140px;
  }
  .ud-brands .ud-brands-logo .ud-single-logo img {
  width: 100%;
    height: 90px;
  }
  .ud-brands .ud-brands-logo .ud-single-logo img{
    filter:none;
  }