/* Bread Crumbs */


.breadcrumbs {
  position: relative;
  padding-top: 160px;
  padding-bottom: 120px;
  z-index: 2;
  text-align: left;
  background-color: var(--bs-blue) !important;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.075);
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.075);
}

.breadcrumbs::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #081828;
  opacity: 0.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    padding-top: 110px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

.breadcrumbs .breadcrumbs-content {
  position: relative;
  text-align: center;
}

.breadcrumbs .breadcrumbs-content .page-title {
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  position: relative;
  line-height: 28px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 22px;
    text-align: center;
    line-height: 26px;
  }
}

.breadcrumbs .breadcrumbs-content .breadcrumb-nav {
  background: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-top: 10px;
}

.breadcrumbs .breadcrumb-nav {
  text-align: right;
}

@media (max-width: 767px) {
  .breadcrumbs .breadcrumb-nav {
    text-align: center;
    margin-top: 15px;
  }
}

.breadcrumbs .breadcrumb-nav li {
  display: inline-block;
  position: relative;
  padding-right: 14px;
  margin-right: 14px;
  text-transform: capitalize;
  color: #fff;
}

.breadcrumbs .breadcrumb-nav li:after {
  content: "\ea5c";
  font-family: lineIcons;
  font-size: 11px;
  position: absolute;
  top: 3px;
  right: -7px;
}

.breadcrumbs .breadcrumb-nav li:last-child {
  margin: 0;
  padding: 0;
}

.breadcrumbs .breadcrumb-nav li:last-child::after {
  display: none;
}

.breadcrumbs .breadcrumb-nav li,
.breadcrumbs .breadcrumb-nav li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.breadcrumbs .breadcrumb-nav li i,
.breadcrumbs .breadcrumb-nav li a i {
  font-size: 13px;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: -1px;
}

.breadcrumbs .breadcrumb-nav li a {
  position: relative;
}

.breadcrumbs .breadcrumb-nav li a:hover {
  color: #e4e4e4;
}